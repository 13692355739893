import { useEffect, useState, useContext } from "react";
import logo from "./img/walkwise.svg";
import './App.css';
import { CircularProgress, ThemeProvider, createTheme, Button, GlobalStyles, Divider } from '@mui/material';
import { useApi, convert, checkTokenExpiration } from "./utilities";
import UserContext from './UserContext.js';
import Cookies from 'js-cookie';

import moment from 'moment-timezone';
import Color from 'color';

import { CategoryScale,LinearScale, Chart as ChartJS, BarElement, Title } from "chart.js";
import { Bar } from "react-chartjs-2";


import {CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const TOKEN_EXPIRED_URL = "https://walkwise.grandpad.net";

ChartJS.register(CategoryScale,LinearScale,BarElement,Title);
ChartJS.defaults.font.size = 16;

//eyJhbGciOiJSUzI1NiIsImtpZCI6InJQd2FSeVFEYy1wbXNsTXlncUM5U25pMXliRXd3Tm9BcE9OTnFGbTR1NGciLCJ0eXAiOiJKV1QifQ.eyJzdWIiOiIxOTIwOGQyYi1jM2E3LTRmNzUtODkyNS02YmRhZTY3OTE4MTEiLCJ0aWQiOiI1YjEwNjdjMy0zZWU3LTQ5NzItYmI5Ni1mMjQzOTBmMjIyYmUiLCJuYW1lIjoic2FtcGxlIiwiZXh0ZW5zaW9uX3VzZXJpZCI6Ijc5MjI4NTljLWQzOWUtNDQxOC04YThmLWE5ZDcyYTdjMTRjOCIsInBob25lX251bWJlciI6IisxNTAzNTA3MTg2MiIsImVtYWlsIjoic2FtcGxlQHdhbGt3aXNlLmNvbSIsInNjcCI6ImRlZmF1bHQiLCJhenAiOiJiNTdlZWIwZi01NWYzLTQwY2UtOWJiMC02NjBlNTgxYjhlNjYiLCJ2ZXIiOiIxLjAiLCJpYXQiOjE2OTgzNTQ3NTQsImF1ZCI6IjU3YzUwYjAwLWI4NGYtNDdkMi1hY2YyLThhYWUxZmM0NDNlZiIsImV4cCI6MTY5ODM1ODM1NCwiaXNzIjoiaHR0cHM6Ly93YWxrd2lzZWF1dGguYjJjbG9naW4uY29tLzViMTA2N2MzLTNlZTctNDk3Mi1iYjk2LWYyNDM5MGYyMjJiZS92Mi4wLyIsIm5iZiI6MTY5ODM1NDc1NH0.oYBNx7LwFsX_kKpUxH3lUkiP6BoHm5_FOj7eoJoODw_ATZHIdV1FXvZ1hGPp9xuG2iCqeLqROJIs0B5Hv5wAqHWj2F6AMYhlJw5IIGgw8GLXEC4MT3nf529-iyT9eMEyAs2u8sJr_iD9d2cbLgtGjnrYG02tEAS8v19lDC8uSv9OsPRUrZvdkwMbtIjTp-2_c_lQrEyio3kS5qJ2DPOIvuiQm_8GSxe_mzqzsksD7lj7kiuvajIRrLk1D-MkSgPKz6PNuqEKncGI3gocv_WKbvYFV1UvMjllWh_NX7XJ7QnXH68Bl5ta5Mrnhjw6W82AJeIYx1mmVPmbAlUXsAJAtg

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
          styles={{
            body: { backgroundColor: "e9e9e9" },
          }}
        />
      <MainView/>
    </ThemeProvider>
  );
}

function MainView(){

  const [user,setUser] = useState(null);

  const [devices,setDevices] = useState(null);

  const [tokenError,setTokenError] = useState(false);

  const [loading,setLoading] = useState(true);

  const {api,setApiToken} = useApi();

  useEffect(()=>{
    console.log("USE EFFECT?");
    checkForToken();
  },[]);

  const getUserUnit = async(userid)=>{
    var unit = "feet";
    try {
      var userFromServer = await api({method:"get",url:`/users/${userid}`});
      //console.log(userFromServer);
      unit = userFromServer.unit == "meters"? "meters":"feet";
      console.log("got user settings from server");
    } catch (e){
      console.log("Could not get user settings from server");
    }

    return unit;
  }

  const getUserDevices = async(userid)=>{
    try {
      var {data} = await api({method:"get",url:`/users/${userid}/devices`});
      console.log("got user devices");
      return data;
    } catch (e){
      console.log("Could not get user devices from server");
      return null;
    }
  }

  const checkForToken = async()=>{
    console.log("Check for tokens...");
    const queryParameters = new URLSearchParams(window.location.search);
    var newToken = queryParameters.get("token");

    if (!newToken){
      newToken = Cookies.get("token");
    }

    try {
      var decoded = checkTokenExpiration(newToken); //will redirect if expired or error

      var newUser = {
        userid: decoded.extension_userid,
        token: newToken,
      }
      // console.log(newUser);
      Cookies.set("token",newToken);
      setApiToken(newToken);

      var unit = await getUserUnit(newUser.userid);

      newUser.unit = unit;

      setUser(newUser);

      var devices = await getUserDevices(newUser.userid);

      setDevices(devices);

      setTimeout(()=>{
        setLoading(false);
      },2000)
      
    } catch (e){
      console.log(e);
      setUser(null);
      setTokenError(true);
    }
  }

  return tokenError?<NoTokenScreen/>:(
    loading?
    <LoadingScreen/>:
    <UserContext.Provider value={user}>
      <UserView devices={devices}/>
    </UserContext.Provider>
  );

}

function NoTokenScreen(){
  return <div style={{height:'100%',width:'100%',position:'fixed',top:'0px',left:'0px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <h1 style={{color:'#800080'}}>Please reload the app.</h1>
  </div>
}

function LoadingScreen(){
  return <div style={{height:'100%',width:'100%',position:'fixed',top:'0px',left:'0px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <img style={{height:60}} src={logo} alt={"WalkWise"}></img>
    <h1 style={{color:'#800080',paddingTop:10}}>Loading WalkWise...</h1>
    <CircularProgress/>
  </div>
}

function UserView({devices}){

  const [selectedDeviceID,setSelectedDeviceID] = useState(null);

  useEffect(()=>{
    if (devices !== null && devices !== undefined && devices.length > 0){
      setSelectedDeviceID(devices[0].deviceid);
    }
  },[devices]);

  if (devices == null || devices == undefined || devices.length == 0){
    return <div style={{padding:20}}><h3>No devices found in your WalkWise account!</h3></div>
  } else if (selectedDeviceID == null){
    <CircularProgress/>
  } else {
    var selectedDevice = devices.find((v)=>{return v.deviceid == selectedDeviceID});
    return <div style={{padding:0}}>
      <div style={{margin:0}}>
      {devices.map(d=>{
        return <DeviceButton key={d.deviceid} device={d} selected={d.deviceid == selectedDeviceID} setDeviceID={setSelectedDeviceID}/>;
      })}
      </div>
      <DeviceView device={selectedDevice}/>
      <img style={{height:60,marginLeft:10,marginTop:20,marginBottom:20}} src={logo} alt={"WalkWise"}></img>
    </div>
  }
}

function DeviceButton({device,selected,setDeviceID}){
  return <Button
    key={device.deviceid}
    variant={selected?"contained":"outlined"}
    style={{margin:10,marginRight:20,padding:20,textTransform:"unset",fontSize:20,borderRadius:30}}
    onClick={()=>{
      setDeviceID(device.deviceid);
    }}
  >{device.username}</Button>
}

function DeviceView({device}){

  const [summaryData,setSummaryData] = useState(null);
  const [loading,setLoading] = useState(true);

  const {api} = useApi();

  useEffect(()=>{
    getSummaryInfo();
    const interval = setInterval(getSummaryInfo, 60000);
    return () => clearInterval(interval);
  },[device]);

  const getSummaryInfo = async function(){
    try {
      setLoading(true);
      setSummaryData(null);
      var {data} = await api({method:"get",url:`/devices/${device.deviceid}/summarydata`});
      setSummaryData(data);
      setLoading(false);
    } catch (err) {
      //show error is data is null yet loading is false
      setSummaryData(null);
      setLoading(false);
    } 
  }

  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%',flexWrap:'wrap'}}>
      <div style={{display:'flex',flexDirection:'row'}}>
      <CurrentProgressBlock durationmode={device.durationmode} data={summaryData} loading={loading}/>
			<LastMovementBlock durationmode={device.durationmode} data={summaryData} loading={loading}/>
      </div>
      <GraphBlock deviceid={device.deviceid} timezone={device.timezone}/>
    </div>
  )

}

export function CurrentProgressBlock(props){

	var user = useContext(UserContext);
	
	var dailyDistanceText = " ";
	var dailyDistanceText2 = "Loading...";
	var distanceGoalText = " ";
	var percentage = 0;

	if (props.loading){
		dailyDistanceText2 = "Loading...";
	} else if (props.data){
		
		if (props.data.currentdistance){
			var distanceGoal = 1000;
			if (props.data.distancegoal){
				distanceGoal = props.data.distancegoal;
			}
			distanceGoalText = convert(distanceGoal,user.unit === 'feet') + ((user.unit === 'feet')?' foot':' meter') + " goal";
			
			dailyDistanceText = convert(props.data.currentdistance,user.unit === 'feet');
			
			dailyDistanceText2 = `${user.unit=="feet"?"feet":"meters"} today`;
			percentage = (props.data.currentdistance/distanceGoal) * 100;
			
		} else if (props.data.currentduration){
			var durationGoal = 30;
			if (props.data.durationgoal){
				durationGoal = props.data.durationgoal;
			}
			var currentRounded = Math.round(props.data.currentduration/60);

			distanceGoalText = `${durationGoal} minute goal`;
			
			dailyDistanceText = `${currentRounded}`;
			
			dailyDistanceText2 = "minutes today";
			percentage = (props.data.currentduration/(durationGoal*60)) * 100;
		
		} else {
			dailyDistanceText = " ";
			dailyDistanceText2 = "No Data Today";
		}
		
	}

	var color = "#800080";
	
	return(
		<div className="dataBlock">
      <div style={{margin:20}}>
      <CircularProgressbarWithChildren styles={buildStyles({pathColor:color})} value={percentage}>
        <div style={{fontSize:'45px',lineHeight:'40px',color:color,paddingBottom:0}}>{`${dailyDistanceText}`}</div>
        <div style={{fontSize:'25px',paddingTop:0}}>{`${dailyDistanceText2}`}</div>
        {/* <div style={{fontSize:'16px',paddingTop:0}}>{distanceGoalText}</div> */}
      </CircularProgressbarWithChildren>
      </div>
		</div>
	)
	
}



function LastMovementBlock(props){

  var walkMinutes = NaN;
	var checkinMinutes = NaN;
	var difference = undefined;
	var hours = 0;
	var text = "";
	var walkColor = "grey";
	var checkinHours = 0;
	var checkinText = "";
  var text = "";
  var checkinColor = "grey";

  var recentCheckin = false;

  if (props.data){
    walkMinutes = props.data.lastwalk;
    if (walkMinutes !== undefined && walkMinutes !== null){
      walkMinutes = moment().diff(props.data.lastwalk,'minutes');
    } else {
      walkMinutes = NaN;
    }
    
    checkinMinutes = props.data.lastcheckin;
    var noCheckinData = props.data.lastcheckin == undefined;
    if (checkinMinutes !== undefined && checkinMinutes !== null){
      checkinMinutes = moment().diff(props.data.lastcheckin,'minutes');
      difference = moment(props.data.lastcheckin).diff(props.data.lastwalk,'minutes');
    } else {
      checkinMinutes = NaN;
      difference = NaN;
    }

    if (noCheckinData){ //for person-based 
      checkinMinutes = 0;
      difference = 0;
      recentCheckin = true;
    }
    
    if (walkMinutes > 120){
      hours = parseInt(walkMinutes/60);
      if (hours > 23){
        text = text + "over a day ago";
        walkColor = 'grey';
      } else {
        text = text + hours + " hours ago";
        walkColor = 'cornflowerblue';
      }
    } else if (walkMinutes < 2){
      text = text + " just now";
      walkColor = 'seagreen';
      recentCheckin = true;
    } else if (isNaN(walkMinutes)){
      text = text + "None";
    } else {
      text = text + walkMinutes + " minutes ago";
      walkColor = "seagreen";
      recentCheckin = true;
    }
    
    if (checkinMinutes < 60 && difference > 180 && difference < 24*60){ //TODO: only change to red during the day
      walkColor = "darkred";
    }

    if (!isNaN(checkinMinutes) && checkinMinutes > walkMinutes){
      checkinMinutes = walkMinutes;
    }
    
    if (checkinMinutes > 120){
      checkinHours = parseInt(checkinMinutes/60);
      if (checkinHours > 23){
        checkinText = checkinText + "over a day ago";
        checkinColor = 'grey';
      } else {
        checkinText = checkinText + checkinHours + " hours ago";
        checkinColor = 'cornflowerblue';
      }
    } else if (checkinMinutes < 2){
      checkinText = checkinText + " just now";
      checkinColor = 'seagreen';
      recentCheckin = true;
    } else if (isNaN(checkinMinutes)){
      checkinText = "never updated";
    } else {
      checkinText = checkinText + checkinMinutes + " minutes ago";
      checkinColor = "seagreen";
      recentCheckin = true;
    }
  }

  return <div className="dataBlock" style={{display:"flex",justifyContent:'center'}}>
    {props.loading?<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}><CircularProgress/></div>:
    <div style={{display:'flex',flexDirection:'column',justifyContent:'stretch',padding:'10px',flex:1}}>
      <div style={{backgroundColor:walkColor,flex:1,borderRadius:10,marginBottom:5,textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <p style={{color:'white',margin:0}}>LAST MOTION</p>
        <p style={{color:'white',fontSize:25,margin:0}}>{text}</p>
      </div>
      <div style={{backgroundColor:"grey",flex:1,borderRadius:10,marginTop:5,textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <p style={{color:'white',margin:0}}>LAST COMMUNICATION</p>
        <p style={{color:'white',fontSize:25,margin:0}}>{checkinText}</p>
      </div>
    </div>}
  </div>
}

function GraphBlock({deviceid,timezone}){

  const [type,setType] = useState("distance");
  const [span,setSpan] = useState("month");

  return <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
    <div className="dataBlockLarge" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <DataChart type={type} span={span} deviceid={deviceid} timezone={timezone} />
    </div>
    <div className="dataBlock" style={{display:'flex',flexDirection:'column'}}>
      <Button 
        variant={type=="distance"?"contained":"outlined"} 
        style={{margin:10,flex:1,borderRadius:20,fontSize:20}}
        onClick={()=>{setType("distance")}}
        >Distance</Button>
      <Button 
        variant={type=="duration"?"contained":"outlined"} 
        style={{margin:10,flex:1,borderRadius:20,fontSize:20}}
        onClick={()=>{setType("duration")}}
        >Time</Button>
      <Divider/>
      <Button 
        variant={span=="month"?"contained":"outlined"} 
        color="secondary" style={{margin:10,flex:1,borderRadius:20,fontSize:20}}
        onClick={()=>{setSpan("month")}}
        >One Month</Button>
      <Button 
        variant={span=="sixmonths"?"contained":"outlined"} 
        color="secondary" style={{margin:10,flex:1,borderRadius:20,fontSize:20}}
        onClick={()=>{setSpan("sixmonths")}}
        >Six Months</Button>
    </div>
  </div>

}

function DataChart({type,span,deviceid,timezone}){
  const {api} = useApi();
  var context = useContext(UserContext);

  const [loading,setLoading] = useState(true);
	const [error,setError] = useState(false);
	const [data,setData] = useState(null);

  useEffect(()=>{
    getChartData();
  },[span,deviceid])

  function getDayBins(data){

    var enddate = data[data.length - 1].date;
    var startdate = data[0].date;

    var bins = [];

    if (span == "month"){
      bins = data.map((d)=>{
        var y = type=="distance"?d.distance:(d.duration/60);
        return {
          date: d.date,
          x: moment(d.date).format('M/D'),
          y: y.toFixed(1)
        }
      })
    } else if (span == "sixmonths"){
      var startOfMonth = moment(startdate).startOf('month');
      var bin = {
        x: startOfMonth.format('MMM'),
        y: 0
      }
      data.forEach(d=>{
        var metric = type=="distance"?d.distance:d.duration;
        if (!metric){
          metric = 0;
        }

        if (moment(d.date).isSame(startOfMonth,'month')){
          bin.y = bin.y + metric;
        } else {
          bins.push(bin);
          startOfMonth = moment(d.date).startOf('month');
          bin = {
            x: startOfMonth.format('MMM'),
            y: metric
          };
        }
      });
      bins.push(bin);

      if (type == "duration"){
        bins = bins.map(b=>{
          return {
            x: b.x,
            y: (b.y/60).toFixed(0)
          }
        })
      }
      
    }

		return bins;
	}

  async function getChartData(){
		console.log("GET CHART DATA!!!!!!!");

    setLoading(true);
    setData(null);
    setError(false);

		var enddate = moment().tz(timezone).subtract(1,'days').format("YYYY-MM-DD");

		if (deviceid == undefined){
			console.log("No deviceid to get data for.");
			return;
		}

    var startdate = moment(enddate).subtract(31,"days").format("YYYY-MM-DD");
    if (span == "sixmonths"){
      startdate = moment(enddate).subtract(6,"months").format("YYYY-MM-DD");
    }
		

    try {

      var url = `/devices/${deviceid}/daytotaldata`;

      var {data} = await api({
        method:'get',
        url: url,
        params: {
          startdate: startdate,
          enddate: enddate,
          includeanalytics: false
        },
        data: null
      });

      var convertAndTrimData = function(d){
        if (context.unit === "feet"){
          for (let i = 0; i < d.length; i++){
            d[i].distance = convert(d[i].distance,true);
          }
        }
        return d;
      }

      var convertedData = convertAndTrimData(data);

      setData(convertedData);
      setLoading(false);
      setError(false);

    }catch(error){
      console.log(error);
      setLoading(false);
      setError(true);
    };
	
	}

  if (error){
    return <p style={{fontSize:24}}>Error getting data.</p>;
  } else if (loading){
    return <CircularProgress/>;
  } else if (data == undefined || data == null || data.length < 1){
    return <p style={{fontSize:24}}>No data to show</p>;
  } else if (data && data[0] && data[0].date) {
    var color = Color(`rgba(127, 0, 127, 1)`);
    switch(type){
      case "duration": color = Color(`rgba(100, 149, 237,1)`); break;
      default: color = Color(`rgba(127, 0, 127,1)`); break;
    }

    var bins = getDayBins(data);
    
    var labels = [];
    for (var i = 0; i < bins.length; i++){
      labels.push(bins[i].x);
    }

    var fill = color.fade(0).rgb().string();
    var h_fill = color.darken(0.2).rgb().string();
    if (span == "month"){
      var fill_array = [];
      for (let i = 0; i < bins.length; i++){
        var dow = moment(bins[i].date).day();
        if (dow == 0 || dow == 6){
          fill_array.push(color.fade(0.5).rgb().string());
        } else {
          fill_array.push(color.fade(0).rgb().string());
        }
      }
      fill = fill_array;
    }

    const datasets = [
      {
        label: '',
        backgroundColor: fill,
        borderColor: fill,
        highlightFill: h_fill,
        highlightStroke: h_fill,
        data: bins.map(b=>b.y),
      }
    ];
    
    var dataForChart = {
      labels: labels,
      datasets: datasets,
    };

    var chartTitle = "";
    if (type == "distance" && span == "month"){
      chartTitle = `Total Daily Distance (${context.unit})`;
    } else if (type == "distance" && span == "sixmonths"){
      chartTitle = `Total Monthly Distance (${context.unit})`;
    } else if (type == "duration" && span == "month"){
      chartTitle = `Total Daily Minutes`;
    } else if (type == "duration" && span == "sixmonths"){
      chartTitle = `Total Monthly Minutes`;
    }

    const options = {
      scales: {
        x: {
          grid: {display:false},
          stacked: true
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
          stacked: true
        },
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: chartTitle,
        },
      },
    };


    return <Bar data={dataForChart} options={options} width='580px' height='280px'/>
  } else {
    return <p>Internal Error</p>;
  }


}



export default App;

const theme = createTheme({
  palette: {
    primary: {
      main: '#800080',
    },
    secondary: {
      main: '#000080',
    },
  },
  typography:{
    fontFamily: 'Poppins'
  },
});