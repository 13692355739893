import axios from 'axios';
import jwt_decode from "jwt-decode";
import { TOKEN_EXPIRED_URL } from "./App";

const axios_api = axios.create({
	//baseURL: 'https://api.walkwise.com/v1',
	baseURL: process.env.REACT_APP_MODE == 'testing' ? 'https://walkwiseapi2-testing.azurewebsites.net/v1' : (process.env.REACT_APP_MODE == 'staging'? 'https://walkwiseapi2-staging.azurewebsites.net/v1':'https://api2.walkwise.com/v1'),
	//baseURL: 'https://walkwiseapi2-staging.azurewebsites.net/v1',
	//baseURL: 'http://localhost:8080/v1',
	timeout: 30000
});

var setToken = null;

export function checkTokenExpiration(token){
	try {
		var decoded = jwt_decode(token);
		var exp = decoded.exp;
		const now = (new Date().getTime()/1000);
		if (exp < now){
			console.log("TOKEN EXPIRED");
			window.location.href = TOKEN_EXPIRED_URL; //'https://google.com';
		} else {
			// console.log("Token is valid");
			return decoded;
		}
	} catch (err) {
		console.log("TOKEN ERROR!");
		window.location.href = TOKEN_EXPIRED_URL;
	}
}


export function useApi(args){

	function setApiToken(token){
		axios_api.defaults.headers.common = {'Authorization': `bearer ${token}`};
		setToken = token;
		console.log("Token is set globally");
	}

	async function api(config){
		checkTokenExpiration(setToken);
		return axios_api(config);
	}

	return {api,setApiToken};
}


export function momentToEncodedDateTimeString(_moment){
	try {
		var str = _moment.toISOString();
		var s = str.split(".");
		str = s[0] + "Z";
		str = str.replace(/:/g, "%3A");
		return str;
	} catch {
		return null;
	}
	
}

export function convert(value,feet=false){

	if (value === null || value === undefined){
		return value;
	} else {
		var v = parseInt(value);
		if (feet){
			v = value * 3.28;
		}
		v = Math.trunc(v);
		return v;
	}
}